const contentData = [
  {
    title: "Use PicGen",
    link: "https://forest.aikopanda.com/picgen",
    description:
      "As a subscriber, you get exclusive access to PicGen, Aiko's random VTuber generator."
  },
  {
    title: "Coming Soon",
    link: "https://forest.aikopanda.com/",
    description:
      "The pandas are working hard to get this ready for you."
  },
  {
    title: "Coming Soon",
    link: "https://forest.aikopanda.com/",
    description:
      "The pandas are working hard to get this ready for you."
  },
  {
    title: "Coming Soon",
    link: "https://forest.aikopanda.com/",
    description:
      "The pandas are working hard to get this ready for you."
  }
];

export default contentData;
